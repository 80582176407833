@import '../../shared.scss';

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: $primary;
    box-shadow: inset 0px 0px 16px -4px rgba(0, 0, 0, 0.5);
}

.menu-item {
    color: $on-primary;
    display: block;
    text-decoration: none;
    padding: 8px;
    text-transform: uppercase;
    background-color: $primary;

    &:hover,
    &.active,
    &:focus {
        background-color: lighten($primary, 20%);
    }

    &.active {
        pointer-events: none;
    }
}