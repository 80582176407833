@import '../../../shared.scss';

.stories-container {
    display: flex;
    flex-wrap: wrap;
    // Use negative margin and margin on child elements until row/column gap is possible
    // https://www.w3.org/TR/css-align-3/
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items
    margin: -10px;
}

.story {
    margin: 10px 12px;
    padding: 12px;
    flex-basis: 45%;
    flex-grow: 1;
    overflow-x: auto;
    background-color: $surface;
    box-shadow: 0 0 8px -3px darken($surface, 30%);

    @include small {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.story-preview-image-container {
    display: block;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: 36px;
    float: left;
    
    @include small {
        margin: 8px;
    }
}

.story-preview-image {
    width: 236px;
    min-height: 376px;
    margin-top: 6px;
    box-shadow: 0 0 2px $primary;
}

.story-preview-image-caption {
    text-transform: uppercase;
    background-color: $primary;
    text-align: center;
    box-shadow: 0 0 2px $primary;
}

.story-preview-image-caption, .story-preview-image-caption a {
    color: $on-primary;
}

.story-preview-amazon {
    display: block;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: 36px;
    width: 250px;
    height: 418px;
    float: left;
    
    @include small {
        margin: 8px;
    }
}

.story-title {
    margin: 20px 0;
    text-align: center;
}