@import "../../../shared.scss";

.blog-container {
  display: flex;
  flex-wrap: wrap-reverse;
}

.blog-posts {
  flex: 1 0 256px;
}

.blog-index-title {
  padding-left: 10px;
  text-indent: -10px;
}

.blog-post {
  padding: 12px;
  flex-basis: 45%;
  flex-grow: 1;
  overflow-x: auto;
  background-color: $surface;
  box-shadow: 0 0 1px darken($surface, 30%);

  @include small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.blog-title {
  text-align: center;
}

.blog-subtitle {
  margin-top: -24px;
  text-align: center;
  color: lighten($on-background, 25%);
}

.blog-index-hint {
  font-weight: bold;
}
