@import './shared.scss';

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: stretch;
    min-height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 8px;
    padding-bottom: 0;
    font-family: 'Catamaran', serif;
    font-size: 18px;
    background-color: $primary-variant;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%; // background: url(./images/bubbles.png), linear-gradient(rgb(17, 128, 143) 0, #00003a 900px);
        background: linear-gradient($secondary 0, $primary-variant 900px);
        background-repeat: repeat;
        pointer-events: none;
    }

    @include small {
        padding: 0 16px;
        padding-bottom: 0px;
    }
}

// For compatibility with older browsers
header,
section,
footer,
aside,
nav,
main,
article,
figure {
    display: block;
}

#vignette {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 200px rgba(0, 0, 0, 0.9) inset;

    @include small {
        display: none;
    }
}

#container {
    position: relative;
    width: 80%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-basis: 80%;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);

    @include small {
        width: 100%;
    }
}

#copyright {
    position: relative;
    text-align: center;
    width: 80%;
    min-height: 40px;
    color: $on-primary;
    font-size: 14px;

    &>a {
        color: $on-primary;
    }
}

.content {
    padding: 50px;
    background-color: $background;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap-reverse;
    overflow-x: auto;

    @include small {
        padding: 8px;
    }
}

.content-aside {
    flex: 0 0 256px;
    margin: 10px;
    padding: 12px;
    background-color: $surface;
    box-shadow: 0 0 1px darken($surface, 30%);
}

.content-main {
    flex: 1 1 512px;
    margin: 10px;
}

.bubble {
    position: absolute;
    background-size: cover;
    background-image: url(./images/bubble.svg);
}

a[target="_blank"]:after {
    content: url(./images/external-link.svg);
    padding-left: 2px;
}

.error {
    color: $error-color;
    font-style: italic;
}

.lds-ripple {
    display: block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 128px auto;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid $primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}