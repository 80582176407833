@import '../../shared.scss';

.title {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    background-color: $background;
    margin: 0;
    padding: 20px 0;
    font-size: 30px;
    font-weight: normal;
    font-family: 'Francois One', sans-serif;
}